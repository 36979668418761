export interface IUploads {
    //   dataObject: DataObject;
    xhr?: XMLHttpRequest
    currentChunkIndex: number;
    running?: boolean;
    fileCRC?: number;
    fileMDP?: string;
    action?: string;
    fileRef?: string;
    chunkCrcs?: Array<number>
}
export interface IUploadOptions {
    //   dataObject: DataObject;
    file: File
    fileCRC?: number;
    fileRef?: number;
    uploadRef?: string;
    data?: Array<Object>;
    responseData?: any,
    onProgress?: Function
}

export interface IChunkUploaderOptions extends IUploadOptions {
    reuseFileRef:boolean
}

export interface IFileUploadOptions {
    url?: string,
    useChunks?: boolean,
    viewName?: string,
    chunkUrl?: string,
    skipXhrHeader?: boolean 
}

export type UploadOptions = {
    files?: FileList | Array<Blob>
    onError?: Function
    onCompleted?: Function
    onProgress?: Function
    beforeUpload?: Function
    beforeUploadAsync?: (pBeforeUploadParams: object) => Promise<object | boolean>
}

export type UploadDataOptions = {
    PrimKey: string
    index: number
}